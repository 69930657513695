import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material';
import { FormContext } from '../FormContext/FormContext';
import { imageContent, setThumbnailSubtitle } from '../FormContext/UploadsContext';
import ClearIcon from '@mui/icons-material/Clear';
import { red } from '@mui/material/colors';
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile';

const ThumbnailsGrid = () => {
  const [values, handleSetValues] = useContext(FormContext);

  return (
    <ImageList rowHeight={160} cols={useCheckIsMobile() ? 2 : 5}>
      {values.uploads.files.map((file) =>
        <ImageListItem key={file.id}>
          {imageContent(file)}
          <ImageListItemBar
            title={file.name}
            subtitle={setThumbnailSubtitle(file)}
            actionIcon={
              <IconButton onClick={() => handleSetValues('filesRemoved', file.id)}>
                <ClearIcon style={{ color: red[500] }} />
              </IconButton>
            }
          />
        </ImageListItem>
      )}
    </ImageList>
  );
};

ThumbnailsGrid.propTypes = {
  category: PropTypes.string
};

export default ThumbnailsGrid;
