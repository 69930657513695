import axios from 'axios';
import getEnv from '../getEnv';
import { isMandatory, limitStringToUTF8MB3 } from '../constants';

let supplementId;

const detailsInstance = (suppId) => {
  supplementId = suppId;
  const details = {
    claimNumber: {
      value: '',
      error: false,
      valid: isValidClaimNumber
    },
    uiTotalAmount: {
      value: '',
      error: false,
      valid: isValidAmount
    },
    uiSupplementAmount: {
      value: '',
      error: false,
      valid: isValidAmount
    },
    repairsComplete: {
      value: 'No',
      error: false,
      valid: isMandatory
    },
    linesOfCoverage: {
      value: ['Dwelling'],
      error: false,
      valid: isValidLinesOfCoverage
    },
    usingContractor: {
      value: 'false',
      error: false,
      valid: isMandatory
    },
    contactName: {
      value: getCachedField('contactName') || '',
      error: false,
      valid: isMandatory
    },
    contactEmail: {
      value: getCachedField('contactEmail') || '',
      error: false,
      valid: isValidEmail
    },
    contactPhoneNumber: {
      value: getCachedField('contactPhoneNumber') || '',
      error: false,
      valid: isValidTelephone
    },
    contractorTaxId: {
      value: '',
      error: false,
      valid: isValidFederalTaxIdOrEmpty
    },
    contractorName: {
      value: '',
      error: false,
      valid: isMandatory
    },
    contractorAddress: {
      value: '',
      error: false,
      valid: isMandatory
    },
    contractorCity: {
      value: '',
      error: false,
      valid: isMandatory
    },
    contractorState: {
      value: '',
      error: false,
      valid: isMandatory
    },
    contractorZipCode: {
      value: '',
      error: false,
      valid: isValidZip
    },
    contractorEmail: {
      value: '',
      error: false,
      valid: isValidEmail
    },
    contractorPhoneNumber: {
      value: '',
      error: false,
      valid: isValidTelephone
    }
  };
  return details;
};

const cachedFields = [
  'contactName',
  'contactEmail',
  'contactPhoneNumber',
  'contractorName',
  'contractorAddress',
  'contractorCity',
  'contractorState',
  'contractorZipCode',
  'contractorEmail',
  'contractorPhoneNumber'
];

const isContractorField = (fieldKey) => fieldKey.substring(0,10) === 'contractor';

const getCachedField = (name) => {
  const value = localStorage.getItem(name);
  return value ? value : '';
};

const setCachedContractorFields = (values, setValues, isContractor, usingContractor) => {
  const { details } = values;
  const isUsingContractor = typeof usingContractor === 'string' ? usingContractor : details.usingContractor.value;
  const shouldUseCache = isContractor || isUsingContractor === 'true';
  values.details.usingContractor.value = isUsingContractor;
  Object.keys(details).forEach((key) => {
    const field = details[key];
    if (isContractorField(key)) {
      if (shouldUseCache &&
        // don't use cached contractorEmail if contractor as contractor persona only requires contactEmail
        (!isContractor || key !== 'contractorEmail')
      ) {
        field.value = getCachedField(key);
      } else {
        field.value = '';
      }
    }
  });
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const getRequireContractorDetails = (values) => {
  const isUsingContractor = values.details.usingContractor.value === 'true';
  const isContractor = values.isContractor;
  return isUsingContractor || isContractor;
};

const isValidClaimNumber = async (val) => {
  const correctFormat = /^\d{9}$/;
  if (!correctFormat.test(val)) {
    return false;
  }
  try {
    const isValidResponse = await axios.get(getEnv('CLAIM_NUM_CHECK_URL'), {
      params: {
        claimNumber: val,
        supplementId
      }
    });
    return isValidResponse.status === 200;
  } catch (error) {
    return false;
  }
};

const isValidFederalTaxIdOrEmpty = (val) => {
  const re = /^(\d{2}-\d{7}){0,1}$/;
  return re.test(val);
};

const isValidLinesOfCoverage = (val) => val.length > 0;

const isValidAmount = (val) => val > 0;

const isValidEmail = (val) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-/\d]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
};

const isValidTelephone = (val) => {
  if (val !== null && val !== undefined) {
    const re = /^\([\d]{3}\)[\d]{3}-[\d]{4}$/;
    return re.test(val.replace(/\s+/g, ''));
  } else {
    return false;
  }
};

const isValidZip = (val) => {
  const re = /^\d{5}$/;
  return re.test(val);
};

const validateDetails = async (values, setValues) => {
  let error = false;
  for (const key of Object.keys(values.details)) {
    if (values.isContractor && key === 'contractorEmail') {
      // don't validate contractorEmail if is contractor persona because this field is hidden and not required.
      continue;
    }
    const field = values.details[key];
    const validFn = field.valid;
    if (validFn) {
      if (isContractorField(key)) {
        values.details[key].error = getRequireContractorDetails(values) ? !validFn(field.value) : false;
      } else {
        values.details[key].error = !(await validFn(field.value));
      }
      setValues((prevValues) => ({
        ...prevValues,
        ...values
      }));
      if (values.details[key].error) {
        error = values.details[key].error;
      }
    }
  }
  return !error;
};

const setDetailsInput = async (event, values, setValues) => {
  const { name: fieldName, value } = event.target;
  const field = values.details[fieldName];
  field.value = typeof value === 'string' ? limitStringToUTF8MB3(value) : value;

  if (fieldName === 'usingContractor') {
    setCachedContractorFields(values, setValues, values.isContractor, value);
    return;
  } else if (fieldName === 'contractorTaxId') {
    if (value?.length === 2) field.value += '-';
  }

  // On blur or selector change event
  if (event.type === 'blur' || event.target.tagName === 'SELECT') {
    const validFn = field.valid;
    field.error = !(await validFn(value));
    if (fieldName === 'uiTotalAmount' || fieldName === 'uiSupplementAmount') {
      field.value = parseFloat(value) ? parseFloat(value).toFixed(2) : '';
    }
  }

  // Field caching
  if (!field.error) {
    if (cachedFields.includes(fieldName)) {
      const fieldIsContractor = isContractorField(fieldName);
      if (!fieldIsContractor || (fieldIsContractor && getRequireContractorDetails(values))) {
        localStorage.setItem(fieldName, value);
      }
    }
  }
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setGoogleAddressDetails = (address, values, setValues) => {
  const { details } = values;
  // Clear all existing contractor fields
  Object.keys(details).forEach((key) => {
    const field = details[key];
    if (isContractorField(key)) {
      field.error = false;
      field.value = '';
      localStorage.setItem(key, '');
    }
  });
  // Populate with new values
  for (const [key, value] of Object.entries(address)) {
    const field = details[key];
    field.value = limitStringToUTF8MB3(value);
    if (!field.error) {
      localStorage.setItem(key, field.value);
    }
  }
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

export {
  detailsInstance,
  getRequireContractorDetails,
  isContractorField,
  setCachedContractorFields,
  setDetailsInput,
  setGoogleAddressDetails,
  validateDetails
};
