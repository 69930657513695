import React, { useContext, useState } from 'react';
import { Box, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BodyText, FieldGroup, AlphanumericInput } from '@lmig/lmds-react';
import { FormContext } from '../FormContext/FormContext';
import { REASON } from '../constants';
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#919191'
    },
    '&:hover fieldset': {
      borderColor: '#28A3AF !important',
      border: 'solid 1px'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#28A3AF !important',
      border: 'solid 2px'
    },
    '&.Mui-error fieldset': {
      borderColor: '#E92243 !important'
    },
    '&.Mui-error': {
      backgroundColor: '#fff4f5'
    },
    '& ::placeholder': {
      color: '#919191',
      opacity: 1
    }
  }
});

const UploadReason = () => {
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext);
  const [toggle, setToggle] = useState(values.reason.tags);
  const options = REASON.TAG_OPTIONS;
  const toggleButtonOrientation = useCheckIsMobile() ? 'vertical' : 'horizontal';

  const handleToggle = (_event, toggleState) => {
    setToggle(toggleState);
    handleSetValues('tags', toggleState);
  };

  return (
    <Box style={{ padding: '20px' }}>
      <FieldGroup
        isFull
        disableMargins='all'
        messages={setErrorMsg('reason', ['freeText'])}
        messagesRole='status'
      >
        <StyledTextField
          id='freeText'
          placeholder={REASON.FREETEXT_PLACEHOLDER}
          fullWidth
          multiline
          rows='3'
          variant='outlined'
          name='freeText'
          value={values.reason.freeText}
          error={values.reason.freeTextError}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => handleSetValues('reason', e)}
        />
      </FieldGroup>
      <FieldGroup
        isFull
        disableMargins='all'
      >
        <BodyText>
          <p>
            {REASON.CATEGORIES_TEXT}
          </p>
        </BodyText>
        <ToggleButtonGroup
          id='tags'
          name='tags'
          value={toggle}
          onChange={handleToggle}
          orientation={toggleButtonOrientation}
          fullWidth
          color='info'
        >
          {options.map((option) => (
            <ToggleButton
              key={option}
              value={option}
              style={{ textTransform: 'none' }}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FieldGroup>
      {
        values.reason.tags.includes(REASON.OTHER_CATEGORY.CATEGORY) && 
        <>
          <pre/>
          <FieldGroup
            isFull
            disableMargins='all'
            messages={setErrorMsg('reason', ['otherCategory'])}
            messagesRole='status'
          >
            <AlphanumericInput
              labelVisual={REASON.OTHER_CATEGORY.LABEL}
              name={'otherCategory'}
              id={'otherCategory'}
              placeholder={REASON.OTHER_CATEGORY.PLACEHOLDER}
              value={values.reason.otherCategory}
              filter={REASON.OTHER_CATEGORY.FILTER}
              onChange={(e) => handleSetValues('otherCategory', e)}
              onBlur={(e) => handleSetValues('otherCategory', e)}
              highlightType={values.reason.otherCategoryError ? 'error' : null}
            />
          </FieldGroup>
        </>
      }
    </Box>
  );
};

export default UploadReason;
