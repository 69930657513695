import React, { useContext } from 'react';
import { FormContext } from '../FormContext/FormContext';
import { AlphanumericInput, FieldGroup } from '@lmig/lmds-react';
import { DETAILS } from '../constants';

const TextInput = (props) => {
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext);
  const details = values.details;
  // eslint-disable-next-line react/prop-types
  const { valueKey } = props;
  return (
    <FieldGroup
      isFull
      disableMargins='all'
      messages={setErrorMsg('details', [valueKey])}
      messagesRole='status'
    >
      <AlphanumericInput
        labelVisual={DETAILS[valueKey].label}
        name={valueKey}
        id={valueKey}
        placeholder={DETAILS[valueKey].placeholder}
        value={details[valueKey].value}
        type={DETAILS[valueKey].type}
        mask={DETAILS[valueKey].mask}
        filter={DETAILS[valueKey].filter}
        onChange={(e) => handleSetValues('details', e)}
        onBlur={(e) => handleSetValues('details', e)}
        highlightType={details[valueKey].error ? 'error' : null}
      />
    </FieldGroup>
  );
};

export default TextInput;
