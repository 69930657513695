const getEnvFromHostname = () => {
  const { hostname } = global.window.location;
  const CLAIM_CHECK_PATH = '/righttouch/isValidClaimNumber';

  const env = {
    API: 'https://property-supplements.libertymutual.com',
    HOST: 'property-supplements.libertymutual.com',
    PORT: 443,
    SSL: true,
    ENV: 'production'
  };

  if (hostname.includes('property-supplement-intake-ui.us-east-1')) {
    env.API = 'https://property-supplement-intake-ui.us-east-1.paas.lmig.com';
    env.HOST = 'property-supplement-intake-ui.us-east-1.paas.lmig.com';
  }

  if (hostname.includes('test')) {
    env.API = 'https://property-supplement-intake-ui-test.us-east-1.np.paas.lmig.com';
    env.HOST = 'property-supplement-intake-ui-test.us-east-1.np.paas.lmig.com';
    env.ENV = 'test';
  }

  if (hostname.includes('development')) {
    env.API = 'https://property-supplement-intake-ui-development.us-east-1.np.paas.lmig.com';
    env.HOST = 'property-supplement-intake-ui-development.us-east-1.np.paas.lmig.com';
    env.ENV = 'development';
  }

  if (hostname.startsWith('localhost')) {
    env.API = 'http://localhost:8080';
    env.HOST = 'localhost';
    env.ENV = 'development';
    env.PORT = 8080;
    env.SSL = false;
  }

  if (hostname.startsWith('127.0.0.1')) {
    env.API = 'http://127.0.0.1:8080';
    env.HOST = '127.0.0.1';
    env.ENV = 'development';
    env.PORT = 8080;
    env.SSL = false;
  }

  env.CLAIM_NUM_CHECK_URL = `${env.API}${CLAIM_CHECK_PATH}`;
  return env;
};

const getEnv = (property) => getEnvFromHostname()[property];

export default getEnv;
