import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { BodyText } from '@lmig/lmds-react';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#919191',
  borderStyle: 'dashed',
  backgroundColor: '#F5F5F5',
  color: '#343741',
  height: '17.5rem',
  width: '18.75rem',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

// eslint-disable-next-line react/prop-types
const Dropzone = ({ onDrop, acceptedFiles, dropzoneIcon, dropzoneText }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: acceptedFiles });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box className='container'>
      <Box {...getRootProps({ style })}>
        {dropzoneIcon}
        <input {...getInputProps()} />
        {isDragReject ? (
          <BodyText>
                        You are trying to upload an invalid file type
          </BodyText>
        ) : (
          <BodyText style={{ textAlign: 'center' }}>
            {dropzoneText}
          </BodyText>
        )}
      </Box>
    </Box>
  );
};

Dropzone.propTypes = {
  onDrop: PropTypes.func
};

export default Dropzone;
