import React, { useContext, useEffect, useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BodyText, FieldGroup, Heading, Notification } from '@lmig/lmds-react';
import { FormContext } from '../FormContext/FormContext';
import { getRequireContractorDetails } from '../FormContext/DetailsContext';
import MapSearch from '../Components/MapSearch';
import RadioButtons from '../Components/RadioButtons';
import Selector from '../Components/Selector';
import TextInput from '../Components/TextInput';
import { DETAILS, HEADINGS, NOTIFICATIONS, STATE_LIST } from '../constants';
import axios from 'axios';
import getEnv from '../getEnv';
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile';

const Details = () => {
  const [apiKey, setApiKey] = useState('');
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext);
  const [coverageToggle, setCoverageToggle] = useState(values.details.linesOfCoverage.value);
  const { isContractor } = values;
  const contractorDetailsVisible = getRequireContractorDetails(values);
  const toggleButtonOrientation = useCheckIsMobile() ? 'vertical' : 'horizontal';

  const getGoogleMapsKey = async () => {
    try {
      const { data } = await axios.get(`${getEnv('API')}/maps/key`);
      setApiKey(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error fetching Google Maps Api Key', error);
    }
  };

  const handleCoverageToggle = (_event, toggleState) => {
    setCoverageToggle(toggleState);
    handleSetValues('details', {
      target: {
        name: 'linesOfCoverage',
        value: toggleState
      }
    });
  };

  useEffect(() => {
    if (!apiKey) {
      getGoogleMapsKey();
    }
  }, [apiKey]);

  return (
    <Box style={{ padding: '20px' }}>
      <TextInput valueKey='claimNumber' />
      <Notification
        alert={NOTIFICATIONS.ESTIMATE_TOTAL_VALUE}
        highlightType='informative'
        style={{ marginBottom: '8px' }}
      />
      <TextInput valueKey='uiTotalAmount' />
      <Notification
        alert={NOTIFICATIONS.SUPPLEMENT_VALUE}
        highlightType='informative'
        style={{ marginBottom: '8px' }}
      />
      <TextInput valueKey='uiSupplementAmount' />
      <RadioButtons valueKey='repairsComplete' />
      <FieldGroup
        isFull
        disableMargins='all'
        messages={setErrorMsg('details', ['linesOfCoverage'])}
        messagesRole='status'
      >
        <BodyText>
          <p>
            {DETAILS.linesOfCoverage.label}
          </p>
        </BodyText>
        <ToggleButtonGroup
          id='linesOfCoverage'
          name='linesOfCoverage'
          value={coverageToggle}
          onChange={handleCoverageToggle}
          orientation={toggleButtonOrientation}
          fullWidth
          color='info'
        >
          {DETAILS.linesOfCoverage.options.map((option) => (
            <ToggleButton
              key={option}
              value={option}
              style={{ textTransform: 'none' }}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FieldGroup>
      <Heading type='h4' align='center' style={{ maxWidth: 'inherit' }}>
        {HEADINGS.DETAILS.CONTACT}
      </Heading>
      <TextInput valueKey='contactName' />
      <TextInput valueKey='contactEmail' />
      <TextInput valueKey='contactPhoneNumber' />
      {!isContractor && <RadioButtons valueKey='usingContractor' />}
      {contractorDetailsVisible &&
        <>
          <Heading type='h4' align='center' style={{ maxWidth: 'inherit' }}>
            {isContractor ? HEADINGS.DETAILS.TAX_ID_CONTRACTOR : HEADINGS.DETAILS.TAX_ID_NON_CONTRACTOR}
          </Heading>
          <TextInput valueKey='contractorTaxId' /> 
          <Heading type='h4' align='center' style={{ maxWidth: 'inherit' }}>
            {isContractor ? HEADINGS.DETAILS.GOOGLE_CONTRACTOR : HEADINGS.DETAILS.GOOGLE_NON_CONTRACTOR}
          </Heading>
          {apiKey && <MapSearch mapsApiKey={apiKey} />}
          <Heading type='h4' align='center' style={{ maxWidth: 'inherit' }}>
            {HEADINGS.DETAILS.ADDRESS}
          </Heading>
          <TextInput valueKey='contractorName' />
          <TextInput valueKey='contractorAddress' />
          <TextInput valueKey='contractorCity' />
          <Selector valueKey='contractorState' options={STATE_LIST} />
          <TextInput valueKey='contractorZipCode' />
          {!isContractor && <TextInput valueKey='contractorEmail' />}
          <TextInput valueKey='contractorPhoneNumber' />
        </>
      }
    </Box>
  );
};



export default Details;
