import React, { useCallback, useContext } from 'react';
import cuid from 'cuid';
import Dropzone from './Dropzone';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FormContext } from '../FormContext/FormContext';
import { Heading, IconButton, IconInfo, Popover } from '@lmig/lmds-react';
import { setAcceptedFiles, setDropzoneIcon, setDropzoneText, setPopoverText } from '../FormContext/UploadsContext';
import { MAX_FILE_SIZE, MAX_TOTAL_FILE_SIZE, MAX_FILE_COUNT, MIN_FILE_SIZE } from '../constants';
import { log } from '../logger';

// eslint-disable-next-line react/prop-types
const DocumentUpload = ({ category, hasError, heading, popoverText }) => {
  const [values, handleSetValues] = useContext(FormContext);
  const ERROR_HEIGHT = '16px';

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map(async (file, index) => {
      const reader = new FileReader();
      const bytesToMegaBytes = file.size / (1024 * 1024);
      const fileSize = `${bytesToMegaBytes.toFixed(2)}MB`;

      const logDetails = { 
        supplementId: values.supplementId,
        claimNumber: values.details.claimNumber.value,
        file: {
          category,
          name: file.name,
          type: file.type, 
          size: file.size,
          sizeMB: bytesToMegaBytes,
          inputEventFileIndex: index,
          inputEventFileCount: acceptedFiles.length
        },
        uploadContext: {
          totalUploadSize: values.uploads.totalUploadSize,
          fileCount: values.uploads.files.length
        }
      };
      log({ event: 'file_input', ...logDetails });

      if (bytesToMegaBytes < MIN_FILE_SIZE) {
        log({ event: 'file_input_min_size_error', ...logDetails }, 'warn');
        values.uploads.minFileSizeNames.add(file.name);
      } else if (bytesToMegaBytes > MAX_FILE_SIZE) {
        log({ event: 'file_input_max_size_error', ...logDetails }, 'warn');
        values.uploads.maxFileSizeNames.add(file.name);
      } else if (values.uploads.totalUploadSize + bytesToMegaBytes > MAX_TOTAL_FILE_SIZE) {
        log({ event: 'file_input_max_total_size_error', ...logDetails }, 'warn');
        values.uploads.maxTotalFileSizeNames.add(file.name);
      } else if (values.uploads.files.length + index >= MAX_FILE_COUNT) {
        log({ event: 'file_input_max_count_error',  ...logDetails }, 'warn');
        values.uploads.maxFileCountNames.add(file.name);
      } else {
        values.uploads.totalUploadSize += bytesToMegaBytes;
        reader.onload = function (e) {
          handleSetValues('filesAdded', [{
            id: cuid(),
            category,
            name: file.path,
            rawSize: bytesToMegaBytes,
            src: e.target.result,
            size: fileSize,
            type: file.type
          }]);
        };
        reader.readAsDataURL(file);
        return file;
      }
    });
    if (values.uploads.minFileSizeNames.size > 0) {
      handleSetValues('setMinFileSizeError');
    } else if (values.uploads.maxFileSizeNames.size > 0) {
      handleSetValues('setMaxFileSizeError');
    } else if (values.uploads.maxTotalFileSizeNames.size > 0) {
      handleSetValues('setMaxTotalFileSizeError');
    } else if (values.uploads.maxFileCountNames.size > 0) {
      handleSetValues('setMaxFileCountError');
    }
  }, [category, handleSetValues, values.uploads]);

  return (
    <Box style={{ margin: '20px', paddingBottom: '20px,', paddingTop: hasError ? '0px' : ERROR_HEIGHT }}>
      <Dropzone
        onDrop={onDrop}
        acceptedFiles={setAcceptedFiles(category)}
        dropzoneIcon={setDropzoneIcon(category)}
        dropzoneText={setDropzoneText(category)}
      />
      <Box display='flex' justifyContent='center' width='19rem'>
        <Heading type='h4' align='center' noWrap style={{ paddingTop: '1.25rem', paddingBottom: '1.25rem' }}>
          {heading}
        </Heading>
        <Popover
          style={{ top: '12px' }}
          trigger={
            <IconButton background='transparent'>
              <IconInfo color='teal' size='16' />
            </IconButton>
          }
        >
          {setPopoverText(category, popoverText)}
        </Popover>
      </Box>
    </Box>
  );
};

DocumentUpload.propTypes = {
  category: PropTypes.string,
  text: PropTypes.string
};

export default DocumentUpload;
