import React, { createContext, useEffect, useState } from 'react';
import { validateDetails, setCachedContractorFields, setDetailsInput, setGoogleAddressDetails, detailsInstance } from './DetailsContext';
import { setReasonText, setReasonTags, validateReason, reasonInstance, setOtherCategoryText } from './ReasonContext';
import {
  setMinFileSizeError,
  setMinFileSizeErrorIsCleared,
  setMaxFileSizeError,
  setMaxFileSizeErrorIsCleared,
  setMaxTotalFileSizeError,
  setMaxTotalFileSizeErrorIsCleared,
  setMaxFileCountError,
  setMaxFileCountErrorIsCleared,
  setRemovedFiles,
  setUploadedFiles,
  validateUploads,
  uploadsInstance
} from './UploadsContext';
import { ERROR_LIST, MAX_FILE_NAME_LENGTH, PERSONA_MODAL, SUBMIT_MODAL, STEP_NAMES, TRACK_EVENTS } from '../constants';
import { postIngestionData } from '../postIngestionData';
import { logEnteredStep, logSubmitFailed, logSubmitOk, logSubmitStarted, logValidateFailed } from '../logger';
import cuid from 'cuid';
import axios from 'axios';
import { trackAnalyticsEvent } from '../Helpers/Analytics/AnalyticsManager';

const stepNames = Object.values(STEP_NAMES);
export const FormContext = createContext();

export const setSubmitHeader = (submitProgress) => {
  switch (submitProgress) {
  case 'submitting':
    return SUBMIT_MODAL.HEADERS.SUBMITTING;
  case 'success':
    return SUBMIT_MODAL.HEADERS.SUCCESS;
  default:
    return SUBMIT_MODAL.HEADERS.ERROR;
  }
};

export const setSubmitText = (submitProgress) => {
  switch (submitProgress) {
  case 'submitting':
    return SUBMIT_MODAL.TEXT.SUBMITTING;
  case 'success':
    return SUBMIT_MODAL.TEXT.SUCCESS;
  default:
    return SUBMIT_MODAL.TEXT.ERROR;
  }
};

// eslint-disable-next-line react/prop-types
export const FormProvider = ({ children }) => {
  const supplementId = cuid();
  const [values, setValues] = useState({
    activeStep: 0,
    contactPersona: PERSONA_MODAL.PERSONA_OPTIONS[0],
    isContractor: false,
    showPersonaModal: true,
    showSubmitModal: false,
    submitProgress: '',
    supplementId,
    details: detailsInstance(supplementId),
    reason: reasonInstance(),
    uploads: uploadsInstance()
  });
  const [ip, setIp] = useState('');

  const getUserIp = async () => {
    const response = await axios.get('https://api.ipify.org?format=json');
    setIp(response.data.ip);
  };

  useEffect(() => {
    if (!ip) {
      getUserIp();
    }
  }, [ip]);

  const trackEvent = (state) => {
    let payload = {};
    let isPageLoad = false;
    switch (state) {
    case TRACK_EVENTS.LINE_OF_COVERAGE:
      payload = { eventName: 'linesOfCoverageSelection', linesOfCoverage: values.details.linesOfCoverage.value };
      break;
    case TRACK_EVENTS.SUPPLEMENT_CATEGORY:
      payload = { eventName: 'supplementCategorySelection', supplementCategory: values.reason.tags };
      break;
    case TRACK_EVENTS.STEP_CHANGE:
      payload = { pageSubName:  stepNames[values.activeStep]?.value, policyProvider: 'liberty' };
      isPageLoad = true;
      break;
    case TRACK_EVENTS.PERSONA_SELECTED:
      payload = { eventName: 'userTypeSelection', userType: values.contactPersona };
      break;
    case TRACK_EVENTS.SUBMIT:
      payload = { eventName: 'supplementSubmitted' };
      break;
    case TRACK_EVENTS.SUBMIT_ERROR:
      payload = { eventName: 'supplementSubmitError' };
      break;
    default:
      break;
    }
    if (values.details && values.details.claimNumber && values.details.claimNumber.value !== '') {
      payload.claimNumber = values.details.claimNumber.value;
    }
    trackAnalyticsEvent(payload, isPageLoad);
  };

  const handleSetValues = (stateType, event) => {
    switch (stateType) {
    case 'activeStep':
      setActiveStep(event);
      break;
    case 'details':
      setDetailsInput(event, values, setValues);
      break;
    case 'googleDetails':
      setGoogleAddressDetails(event, values, setValues);
      break;
    case 'persona':
      setContactPersona(event, values, setValues);
      break;
    case 'personaSelected':
      setPersonaSelectionComplete(event, values, setValues);
      trackEvent(TRACK_EVENTS.PERSONA_SELECTED);
      break;
    case 'reason':
      setReasonText(event, values, setValues);
      break;
    case 'tags':
      setReasonTags(event, values, setValues);
      break;
    case 'otherCategory':
      setOtherCategoryText(event, values, setValues);
      break;
    case 'filesAdded':
      setUploadedFiles(event, values, setValues);
      break;
    case 'filesRemoved':
      setRemovedFiles(event, values, setValues);
      break;
    case 'setMinFileSizeError':
      setMinFileSizeError(event, values, setValues);
      break;
    case 'clearMinFileSizeError':
      setMinFileSizeErrorIsCleared(event, values, setValues);
      break;
    case 'setMaxFileSizeError':
      setMaxFileSizeError(event, values, setValues);
      break;
    case 'clearMaxFileSizeError':
      setMaxFileSizeErrorIsCleared(event, values, setValues);
      break;
    case 'setMaxTotalFileSizeError':
      setMaxTotalFileSizeError(event, values, setValues);
      break;
    case 'clearMaxTotalFileSizeError':
      setMaxTotalFileSizeErrorIsCleared(event, values, setValues);
      break;
    case 'setMaxFileCountError':
      setMaxFileCountError(event, values, setValues);
      break;
    case 'clearMaxFileCountError':
      setMaxFileCountErrorIsCleared(event, values, setValues);
      break;
    case 'submitComplete':
      window.location.reload();
      window.scrollTo({ top: 0 });
      break;
    default:
      break;
    }
  };

  const setActiveStep = async (event) => {
    let newStep = values.activeStep;
    let hasClickedSubmit = false;
    switch (true) {
    case event.target.innerText === 'Next' && values.activeStep === 0:
      trackEvent(TRACK_EVENTS.LINE_OF_COVERAGE);
      if (await validateDetails(values, setValues)) {
        newStep = values.activeStep + 1;
        logEnteredStep(values);
        window.scrollTo({ top: 0 });
        break;
      }
      logValidateFailed(values);
      break;
    case event.target.innerText === 'Next' && values.activeStep === 1:
      trackEvent(TRACK_EVENTS.SUPPLEMENT_CATEGORY);
      if (validateReason(values, setValues)) {
        newStep = values.activeStep + 1;
        logEnteredStep(values);
        window.scrollTo({ top: 0 });
        break;
      }
      logValidateFailed(values);
      break;
    case event.target.innerText === 'Next' && values.activeStep === 2:
      if (validateUploads(values, setValues)) {
        newStep = values.activeStep + 1;
        logEnteredStep(values);
        window.scrollTo({ top: 0 });
        break;
      }
      logValidateFailed(values);
      break;
    case event.target.innerText === 'Back' &&  values.activeStep > 0:
      newStep = values.activeStep - 1;
      logEnteredStep(values);
      break;
    case event.target.innerText === 'Submit':
      hasClickedSubmit = true;
      doPostData();
      logSubmitStarted(values);
      break;
    default:
      newStep = values.activeStep;
      break;
    }
    setValues((prevValues) => ({
      ...prevValues,
      activeStep: newStep,
      showSubmitModal: hasClickedSubmit
    }));
  };

  useEffect(() => {
    trackEvent(TRACK_EVENTS.STEP_CHANGE);
  }, [values.activeStep]);

  const setContactPersona = (event, values, setValues) => {
    const { value } = event.target;
    const isContractor = value.toLowerCase().indexOf('contractor') > -1;
    values.contactPersona = value;
    values.isContractor = isContractor;
    if (isContractor) values.details.usingContractor.value = 'true';

    setCachedContractorFields(values, setValues, isContractor);
    setValues((prevValues) => ({
      ...prevValues,
      ...values
    }));
  };

  const setPersonaSelectionComplete = (event, values, setValues) => {
    values.showPersonaModal = false;
    setValues((prevValues) => ({
      ...prevValues,
      ...values
    }));
  };

  const setErrorMsg = (stateType, errorTypes) => {
    const { details, reason, uploads } = values;
    const errorMessages = [];
    let error = false;
    for (const errorType of errorTypes) {
      switch (stateType) {
      case 'details':
        error = details[errorType].error;
        break;
      case 'reason':
        error = reason[`${errorType}Error`];
        break;
      case 'uploads':
        error = uploads[`${errorType}Error`];
        break;
      default:
        break;
      }
      if (error) {
        const errorMsg = ERROR_LIST[errorType];
        errorMessages.push({
          text: errorMsg,
          type: 'error'
        });
      }
    }
    if (errorMessages.length > 0) {
      return errorMessages;
    } else {
      return undefined;
    }
  };

  const prepareFileNames = () => {
    values.uploads.files.forEach((file, idx) => {
      let fileName = `${idx}_${file.category}_${file.name}`;
      if (fileName.length > MAX_FILE_NAME_LENGTH) {
        // In case of multiple (.) in filename, take the extension by getting the last item in the array
        const fileExtension = fileName.split('.').pop();
        fileName = `${fileName.substring(0, MAX_FILE_NAME_LENGTH - fileExtension.length - 1)}.${fileExtension}`;
      }

      file.name = fileName;
    });
    setValues((prevValues) => ({
      ...prevValues,
      ...values
    }));
  };

  const setSubmitProgress = (progress) => {
    switch (progress) {
    case 'submitting':
      values.submitProgress = 'submitting';
      break;
    case 'success':
      values.submitProgress = 'success';
      break;
    case 'error':
    default:
      values.submitProgress = 'error';
      break;
    }
    setValues((prevValues) => ({
      ...prevValues,
      submitProgress: values.submitProgress
    }));
  };

  const doPostData = () => {
    prepareFileNames();
    setSubmitProgress('submitting');
    postIngestionData(values, ip)
      .then(() => {
        logSubmitOk(values);
        setSubmitProgress('success');
        trackEvent(TRACK_EVENTS.SUBMIT);
      })
      .catch((error) => {
        logSubmitFailed(values, error);
        setSubmitProgress('error');
        trackEvent(TRACK_EVENTS.SUBMIT_ERROR);
      });
  };

  return (
    <FormContext.Provider value={[values, handleSetValues, setErrorMsg]}>
      {children}
    </FormContext.Provider>
  );
};
