/* eslint-disable no-console */
import getEnv from './getEnv';
import axios from 'axios';
import { detect } from 'detect-browser';

export const logger = {
  handleLog: async (level, message, meta) => {
    const logPayload = { level, message, side: SIDE_CLIENT, ...meta };
    if (getEnv('ENV') !== 'production') console[level](message);
  
    try {
      await axios.post('/righttouch/log', logPayload);
    } catch (error) {
      // Attempt to log logging failure once.
      try {
        axios.post('/righttouch/log', { 
          level: 'error',
          side: SIDE_CLIENT,
          event: 'log_send_failed',
          error, 
          attemptedLog: logPayload 
        });
      } catch (error) {
        console.error('Failed to log logging failure; error:', { error });
      }
    }
  },
  info: (message, meta) => logger.handleLog('info', message, meta),
  warn: (message, meta) => logger.handleLog('warn', message, meta),
  error: (message, meta) => logger.handleLog('error', message, meta)
};

const SIDE_CLIENT = 'client';
const getFormattedLogMessage = (data) => `${SIDE_CLIENT} - ${JSON.stringify(data)}`;

// Only include those fields which are currently allowed to appear in logs
const filterDetailsForLogging = (formValues) => {
  const { details } = formValues;
  return {
    claimNumber: details.claimNumber,
    contractorAddress: details.contractorAddress,
    contractorCity: details.contractorCity,
    contractorEmail: details.contractorEmail,
    contractorName: details.contractorName,
    contractorPhoneNumber: details.contractorPhoneNumber,
    contractorState: details.contractorState,
    contractorZipCode: details.contractorZipCode,
    linesOfCoverage: details.linesOfCoverage,
    repairsComplete: details.repairsComplete,
    uiSupplementAmount: details.uiSupplementAmount,
    uiTotalAmount: details.uiTotalAmount,
    usingContractor: details.usingContractor
  };
  // Omitted PII fields: contactName, contactEmail, contactPhoneNumber, contractorTaxId
};

const getFullState = (formValues) => {
  const fileInfo = listFileInfo(formValues.uploads.files);
  const filteredDetails = filterDetailsForLogging(formValues);
  switch (formValues.activeStep + 1) {
  case 1:
    return {
      browserInfo: getBrowserInfo(),
      supplementDetails: filteredDetails
    };
  case 2:
    return {
      supplementDetails: filteredDetails,
      supplementType: formValues.reason
    };
  case 3:
    return {
      supplementDetails: filteredDetails,
      supplementType: formValues.reason,
      supplementUpload: fileInfo,
      totalUploadSize: formValues.uploads.totalUploadSize
    };
  case 4:
    return {
      supplementDetails: filteredDetails,
      supplementType: formValues.reason,
      supplementUpload: fileInfo,
      totalUploadSize: formValues.uploads.totalUploadSize
    };
  default:
    return {};
  }
};

const getStepState = (formValues) => {
  const fileInfo = listFileInfo(formValues.uploads.files);
  const filteredDetails = filterDetailsForLogging(formValues);
  switch (formValues.activeStep + 1) {
  case 1:
    return {
      supplementDetails: filteredDetails
    };
  case 2:
    return {
      supplementType: formValues.reason
    };
  case 3:
    return {
      supplementUpload: fileInfo,
      totalUploadSize: formValues.uploads.totalUploadSize
    };
  default:
    return {};
  }
};

const listFileInfo = (files) => files.map((file) => ({
  fileName: file.name,
  fileSize: file.size
}));

export const logEnteredStep = (formValues) => {
  const data = {
    supplementId: formValues.supplementId,
    claimNumber: formValues.details.claimNumber.value,
    event: 'entered_step',
    step: formValues.activeStep + 1,
    state: getFullState(formValues)
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logValidateFailed = (formValues) => {
  const data = {
    supplementId: `${formValues.supplementId}`,
    claimNumber: `${formValues.details.claimNumber.value}`,
    event: 'validate_failed',
    step: formValues.activeStep + 1,
    state: getStepState(formValues)
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logSubmitStarted = (formValues) => {
  const totalUploadSize = `${formValues.uploads.totalUploadSize.toFixed(
    3
  )}MB`;
  const data = {
    supplementId: `${formValues.supplementId}`,
    claimNumber: `${formValues.details.claimNumber.value}`,
    event: 'submit_started',
    totalUploadSize
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logSubmitOk = (formValues) => {
  const totalUploadSize = `${formValues.uploads.totalUploadSize.toFixed(
    3
  )}MB`;
  const data = {
    supplementId: `${formValues.supplementId}`,
    claimNumber: `${formValues.details.claimNumber.value}`,
    event: 'submit_ok',
    totalUploadSize,
    state: getFullState(formValues)
  };
  logger.info(getFormattedLogMessage(data), data);
};

export const logSubmitFailed = (formValues, error) => {
  const totalUploadSize = `${formValues.uploads.totalUploadSize.toFixed(
    3
  )}MB`;
  const data = {
    supplementId: `${formValues.supplementId}`,
    claimNumber: `${formValues.details.claimNumber.value}`,
    event: 'submit_failed',
    totalUploadSize,
    error,
    statusCode: error.response ? error.response.status : '',
    state: getFullState(formValues)
  };
  logger.error(getFormattedLogMessage(data), data);
};

export const getBrowserInfo = () => {
  const browser = detect();

  if (browser) {
    return {
      browser: {
        name: browser.name,
        version: browser.version,
        os: browser.os
      }
    };
  } else {
    return 'Browser details not found';
  }
};

export const log = (logData, level = 'info') => {
  logger[level](getFormattedLogMessage(logData), logData);
};
