import axios from 'axios';
import getEnv from './getEnv';

export const postIngestionData = (formValues, ip) => new Promise((resolve, reject) => {
  const detailsContext = formValues.details;
  const reasonContext = formValues.reason;
  const uploadsContext = formValues.uploads;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData'
    }
  };

  const data = new FormData();
  data.append('supplementId', formValues.supplementId);
  data.append('contactPersona', formValues.contactPersona);
  data.append('ipAddress', ip);

  Object.keys(detailsContext).forEach((key) => {
    data.append(key, detailsContext[key].value);
  });

  data.append('supplementTypeDescription', reasonContext.freeText);
  data.append('supplementTypeOption', reasonContext.tags);
  data.append('supplementTypeOther', reasonContext.otherCategory);

  for (const file of uploadsContext.files) {
    const fileBlob = dataURItoBlob(file);
    data.append(file.category, fileBlob, file.name);
  }

  axios
    .post(`${getEnv('API')}/righttouch`, data, config)
    .then((response) => {
      response.status === 200 ? resolve() : reject(`axios.post failed with status ${response.status}`);
    })
    .catch((error) => reject(error));
});

const dataURItoBlob = (file) => {
  const binary = atob(file.src.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: file.type });
};
