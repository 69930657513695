import React, { useContext, useEffect } from 'react';
import { FormContext } from '../FormContext/FormContext';
import { Notification, AlphanumericInput } from '@lmig/lmds-react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_LIBRARIES, NOTIFICATIONS } from '../constants';
import PropTypes from 'prop-types';
import './MapSearch.css';

const center = {
  lat: 42.35006,
  lng: -71.07208
};

const MapSearch = (props) => {
  const [values, handleSetValues] = useContext(FormContext);
  const isContractor = values.isContractor;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: props.mapsApiKey,
    libraries: GOOGLE_LIBRARIES
  });
  
  useEffect(() => {
    if (isLoaded) {
      const input = document.getElementById('Google-Map-Search-input');
      const searchBox = new window.google.maps.places.SearchBox(input);
      const map = new window.google.maps.Map(
        document.getElementById('Google-Map'),
        {
          center,
          zoom: 12
        }
      );

      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds());
      });

      // snap to current location
      snapToCurrentLocation(map, navigator.geolocation);

      // handle results from map searches
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        setAddressDetails(places, handleSetValues);
        centerOnSearchResult(map, places);
      });
    }
  }, [isLoaded]);

  if (!isLoaded) return <></>;

  return (
    <>
      <Notification
        alert={isContractor ? NOTIFICATIONS.GOOGLE_CONTRACTOR : NOTIFICATIONS.GOOGLE_NON_CONTRACTOR}
        highlightType='informative'
        style={{ marginBottom: '8px' }}
      />
      <AlphanumericInput
        disabled={!isLoaded}
        id='Google-Map-Search'
        type='text'
        labelVisual='Address search...'
        value=''
      />
      <GoogleMap
        id='Google-Map'
        zoom={12}
        center={{ lat: 42.35006, lng: -71.07208 }}
        mapContainerClassName='MapSearch-container'
      />
    </>
  );
};

const snapToCurrentLocation = (map, geolocation) => {
  if (geolocation) {
    geolocation.getCurrentPosition((position) => {
      map.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
  }
};

const setAddressDetails = (places, handleSetValues) => {
  if (Array.isArray(places) && places.length === 1) {
    const place = places[0];
    const phone = place.formatted_phone_number;
    let streetNumber = '';
    let route = '';
    const fullAddress = {
      contractorName: '',
      contractorAddress: '',
      contractorCity: '',
      contractorState: '',
      contractorZipCode: '',
      contractorPhoneNumber: ''
    };

    // Extract from address_components
    for (const element of place.address_components) {
      const type = element.types[0];
      switch (type) {
      case 'street_number':
        streetNumber = element.short_name;
        break;
      case 'route':
        route = element.short_name;
        break;
      case 'locality':
        fullAddress.contractorCity = element.short_name;
        break;
      case 'postal_code':
        fullAddress.contractorZipCode = element.short_name;
        break;
      case 'administrative_area_level_1':
        fullAddress.contractorState = element.short_name;
        break;
      default:
        break;
      }
    }
    if (streetNumber !== '' && route !== '') {
      fullAddress.contractorAddress = `${streetNumber} ${route}`;
    }

    if (fullAddress.contractorAddress.trim().toLowerCase() !== place.name.trim().toLowerCase()) {
      fullAddress.contractorName = place.name;
    }

    if (phone && phone !== '') {
      fullAddress.contractorPhoneNumber = phone;
    }

    // Set address details
    handleSetValues('googleDetails', fullAddress);
  }
};

const centerOnSearchResult = (map, places) => {
  const bounds = new window.google.maps.LatLngBounds();
  places.forEach((place) => {
    if (place.geometry) {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    }
  });
  map.fitBounds(bounds);
};

MapSearch.propTypes = {
  mapsApiKey: PropTypes.string
};

export default MapSearch;
