import { isMandatory, limitStringToUTF8MB3, REASON } from '../constants';

const reasonInstance = () => {
  const reason = {
    freeText: '',
    freeTextError: false,
    freeTextValid: (freeText) => isMandatory(freeText),
    tags: [],
    otherCategory: '',
    otherCategoryError: false,
    otherCategoryValid: (value) => isMandatory(value)
  };
  return reason;
};

const setReasonText = (event, values, setValues) => {
  const value = event.target.value && limitStringToUTF8MB3(event.target.value);
  values.reason.freeTextError = !values.reason.freeTextValid(value);
  values.reason.freeText = value;
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setReasonTags = (event, values, setValues) => {
  const tags = event;

  if (!tags.includes(REASON.OTHER_CATEGORY.CATEGORY)) {
    values.reason.otherCategory = '';
    values.reason.otherCategoryError = false;
  }
  values.reason.tags = tags;

  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setOtherCategoryText = (event, values, setValues) => {
  const value = event.target.value && limitStringToUTF8MB3(event.target.value);

  values.reason.otherCategory = value;
  if (event.type === 'blur') values.reason.otherCategoryError = !values.reason.otherCategoryValid(value);

  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const validateReason = (values, setValues) => {
  const { reason } = values;
  let error = false;

  reason.freeTextError = !reason.freeTextValid(values.reason.freeText);
  if (reason.tags.includes(REASON.OTHER_CATEGORY.CATEGORY)) reason.otherCategoryError = !reason.otherCategoryValid(reason.otherCategory);

  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));

  error = reason.freeTextError || reason.otherCategoryError;
  return !error;
};

export { reasonInstance, validateReason, setReasonText, setReasonTags, setOtherCategoryText };
