import { ACCEPTED_FILES, ACCEPTED_IMAGES, UPLOADS } from '../constants';
import React from 'react';
import { IconCamera, IconDocument, IconLossofUse } from '@lmig/lmds-react';
import { Box } from '@mui/material';
import FileDoc from '../Assets/Images/FileDoc.png';
import { red } from '@mui/material/colors';

const uploadsInstance = () => {
  const uploads = {
    files: [],
    totalUploadSize: 0,
    noSupplementError: false,
    invalidSupplementError: false,
    invalidPhotosError: false,
    invalidOtherDocumentsError: false,
    minFileSizeError: false,
    maxFileSizeError: false,
    maxTotalFileSizeError: false,
    maxFileCountError: false,
    minFileSizeNames: new Set(),
    maxFileSizeNames: new Set(),
    maxTotalFileSizeNames: new Set(),
    maxFileCountNames: new Set()
  };
  return uploads;
};

const setUploadedFiles = (event, values, setValues) => {
  const files = event;
  values.uploads.files.push(...files);

  for (const file of files) {
    switch (file.category) {
    case 'estimate':
      validateEstimate(values);
      break;
    case 'photos':
      validatePhotos(values);
      break;
    case 'other':
      validateOtherDocuments(values);
      break;
    default:
      break;
    }
  }

  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setRemovedFiles = (event, values, setValues) => {
  const index = values.uploads.files.findIndex((file) => file.id === event);
  if (index > -1) {
    const fileToRemove = values.uploads.files.filter((file) => file.id === event);
    values.uploads.totalUploadSize -= fileToRemove[0].rawSize;
    values.uploads.files.splice(index, 1);
  }
  validateUploads(values);

  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMinFileSizeError = (event, values, setValues) => {
  values.uploads.minFileSizeError = true;
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMinFileSizeErrorIsCleared = (event, values, setValues) => {
  values.uploads.minFileSizeError = false;
  values.uploads.minFileSizeNames.clear();
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxFileSizeError = (event, values, setValues) => {
  values.uploads.maxFileSizeError = true;
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxFileSizeErrorIsCleared = (event, values, setValues) => {
  values.uploads.maxFileSizeError = false;
  values.uploads.maxFileSizeNames.clear();
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxTotalFileSizeError = (event, values, setValues) => {
  values.uploads.maxTotalFileSizeError = true;
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxTotalFileSizeErrorIsCleared = (event, values, setValues) => {
  values.uploads.maxTotalFileSizeError = false;
  values.uploads.maxTotalFileSizeNames.clear();
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxFileCountError = (event, values, setValues) => {
  values.uploads.maxFileCountError = true;
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setMaxFileCountErrorIsCleared = (event, values, setValues) => {
  values.uploads.maxFileCountError = false;
  values.uploads.maxFileCountNames.clear();
  setValues((prevValues) => ({
    ...prevValues,
    ...values
  }));
};

const setThumbnailSubtitle = (file) => !file.src || !file.src.includes(';base64') ? (
  
  <b style={{ color: red[500] }}> Invalid {UPLOADS[file.category.toUpperCase()].FILE_LABEL} File </b>
) : (
  file.size
);

const filterFilesByCategory = (uploads, category) => {
  const files = uploads.files.filter(
    (file) => file.category === category
  );
  const filesWithNoSrc = uploads.files.filter(
    (file) =>
      (!file.src || !file.src.includes(';base64')) &&
          file.category === category
  );
  return { files, filesWithNoSrc };
};

const validateEstimate = (values) => {
  const estimate = filterFilesByCategory(values.uploads, 'estimate');
  values.uploads.invalidSupplementError =  estimate.filesWithNoSrc.length > 0;
  values.uploads.noSupplementError = !values.uploads.invalidSupplementError && estimate.files.length !== 1;

  const invalidEstimate = values.uploads.noSupplementError || values.uploads.invalidSupplementError;
  return !invalidEstimate;
};

const validatePhotos = (values) => {
  const photos = filterFilesByCategory(values.uploads, 'photos');
  values.uploads.invalidPhotosError = photos.filesWithNoSrc.length > 0;

  const invalidPhotos = values.uploads.invalidPhotosError;
  return !invalidPhotos;
};

const validateOtherDocuments = (values) => {
  const otherDocuments = filterFilesByCategory(values.uploads, 'other');
  values.uploads.invalidOtherDocumentsError = otherDocuments.filesWithNoSrc.length > 0;

  const invalidOtherDocuments = values.uploads.invalidOtherDocumentsError;
  return !invalidOtherDocuments;
};

const validateUploads = (values) => {
  const validEstimate = validateEstimate(values);
  const validPhotos = validatePhotos(values);
  const validOtherDocuments = validateOtherDocuments(values);
  return (validEstimate && validPhotos && validOtherDocuments);
};

const setPopoverText = (category, popoverText) => {
  switch (category) {
  case UPLOADS.ESTIMATE.CATEGORY:
    return (
      <Box>
        <p>{popoverText.TEXT_START}</p>
        <li>{popoverText.LIST_ITEM_A}</li>
        <li>{popoverText.LIST_ITEM_B}</li>
        <p>{popoverText.TEXT_END}</p>
      </Box>
    );
  case UPLOADS.PHOTOS.CATEGORY:
    return (
      <Box>
        <p>{popoverText.TEXT_START}</p>
        <p>{popoverText.TEXT_MIDDLE}</p>
        <p>{popoverText.TEXT_END}</p>
      </Box>
    );
  case UPLOADS.OTHER.CATEGORY:
    return (
      <Box>
        <p>{popoverText.TEXT_START}</p>
        <li>{popoverText.LIST_ITEM_A}</li>
        <li>{popoverText.LIST_ITEM_B}</li>
        <li>{popoverText.LIST_ITEM_C}</li>
        <li>{popoverText.LIST_ITEM_D}</li>
        <li>{popoverText.LIST_ITEM_E}</li>
      </Box>
    );
  default:
    break;
  }
};

const setAcceptedFiles = (category) => {
  switch (category) {
  case UPLOADS.PHOTOS.CATEGORY:
    return ACCEPTED_IMAGES;
  case UPLOADS.OTHER.CATEGORY:
    return { ...ACCEPTED_FILES, ...ACCEPTED_IMAGES };
  default:
    return ACCEPTED_FILES;
  }
};

const setDropzoneIcon = (category) => {
  switch (category) {
  case UPLOADS.ESTIMATE.CATEGORY:
    return <IconLossofUse size="64" style={{ marginTop: '40px' }}/>;
  case UPLOADS.PHOTOS.CATEGORY:
    return <IconCamera size="64" style={{ marginTop: '40px' }}/>;
  case UPLOADS.OTHER.CATEGORY:
    return <IconDocument size="64" style={{ marginTop: '40px' }}/>;
  default:
    break;
  }
};

const setDropzoneText = (category) => {
  switch (category) {
  case UPLOADS.ESTIMATE.CATEGORY:
    return (
      <p>{UPLOADS.ESTIMATE.DROPZONE}</p>
    );
  case UPLOADS.PHOTOS.CATEGORY:
    return (
      <p>{UPLOADS.PHOTOS.DROPZONE}</p>
    );
  case UPLOADS.OTHER.CATEGORY:
    return (
      <p>{UPLOADS.OTHER.DROPZONE}</p>
    );
  default:
    break;
  }
};

const imageContent = (image) => {
  const type =
        image.src && image.src.includes(';base64')
          ? image.src.split('/')[0].split(':')[1]
          : 'Invalid file';
  const src = type !== 'image' ? FileDoc : image.src;
  return <img style={{ top: 'auto', left: 'auto' }} alt='X' src={src} />;
};

export {
  uploadsInstance,
  imageContent,
  setAcceptedFiles,
  setDropzoneIcon,
  setDropzoneText,
  setPopoverText,
  setUploadedFiles,
  setRemovedFiles,
  setThumbnailSubtitle,
  setMinFileSizeError,
  setMinFileSizeErrorIsCleared,
  setMaxFileSizeError,
  setMaxFileSizeErrorIsCleared,
  setMaxTotalFileSizeError,
  setMaxTotalFileSizeErrorIsCleared,
  setMaxFileCountError,
  setMaxFileCountErrorIsCleared,
  validateUploads
};
