import React, { useContext } from 'react';
import { FormContext } from '../FormContext/FormContext';
import { FieldGroup, Select, SelectOption } from '@lmig/lmds-react';
import { DETAILS } from '../constants';

const Selector = (props) => {
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext);
  // eslint-disable-next-line react/prop-types
  const { valueKey, options } = props;
  return (
    <FieldGroup
      isFull
      disableMargins='all'
      messages={setErrorMsg('details', [valueKey])}
      messagesRole='status'
    >
      <Select
        labelVisual={DETAILS[valueKey].label}
        name={valueKey}
        id={valueKey}
        placeholderOption={DETAILS[valueKey].label}
        value={values.details[valueKey].value}
        onChange={(e) => handleSetValues('details', e)}
        highlightType={values.details[valueKey].error ? 'error' : null}
        isRequired
      >
        {/* eslint-disable-next-line react/prop-types */}
        {options.map(({ code, contractorState }, index) => (
          <SelectOption key={index} value={code}>
            {code} - {contractorState}
          </SelectOption>
        ))}
      </Select>
    </FieldGroup>
  );
};

export default Selector;
