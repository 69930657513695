import React from 'react';
import './LibertyFooter.css';
import { LIBERTY_FOOTER } from '../constants';
import { Grid } from '@mui/material';
import { Footer, Disclaimer, Link } from '@lmig/lmds-react';

const LibertyFooter = () => {
  const baseUrl = LIBERTY_FOOTER.baseUrl;
  return (
    <Footer fullWidth className='LibertyFooter'>
      <Grid
        container
        className='LibertyFooter-container'
        justifyContent='center'
      >
        <Link href={baseUrl + LIBERTY_FOOTER.links.privacy} onBackground='blue'>
          Privacy Policy
        </Link>
        <span className='LibertyFooter-pipe'>
          &nbsp;&nbsp;&#124;&nbsp;&nbsp;
        </span>
        <Link
          href={baseUrl + LIBERTY_FOOTER.links.security}
          onBackground='blue'
        >
          Security Policy
        </Link>
        <span className='LibertyFooter-pipe'>
          &nbsp;&nbsp;&#124;&nbsp;&nbsp;
        </span>
        <Link
          href={baseUrl + LIBERTY_FOOTER.links.accessibility}
          onBackground='blue'
        >
          Web Accessibility Policy
        </Link>
        <span className='LibertyFooter-pipe'>
          &nbsp;&nbsp;&#124;&nbsp;&nbsp;
        </span>
        <Link href={baseUrl + LIBERTY_FOOTER.links.terms} onBackground='blue'>
          Terms and Conditions
        </Link>
        <span className='LibertyFooter-pipe'>
          &nbsp;&nbsp;&#124;&nbsp;&nbsp;
        </span>
        <Link href={baseUrl + LIBERTY_FOOTER.links.fraud} onBackground='blue'>
          Fraud Protection
        </Link>
      </Grid>
      <Grid
        container
        className='LibertyFooter-lower-container'
        justifyContent='center'
      >
        <Grid container item xs={8}>
          <Grid item xs={6}>
            <Disclaimer>
              <p>
                &copy; {new Date().getFullYear()} Liberty Mutual Insurance
                Company, 175 Berkeley Street, Boston, MA 02116
              </p>
              <p>
                Liberty Mutual is an{' '}
                <Link
                  href={`${baseUrl + LIBERTY_FOOTER.links.equal}`}
                  target='cybertrust'
                >
                  Equal Opportunity Employer
                </Link>{' '}
                and an Equal Housing Insurer.
              </p>
            </Disclaimer>
          </Grid>
          <Grid item xs={6} className='LibertyFooter-logos'>
            <Link
              className='LibertyFooter-icon LibertyFooter-cybertrust'
              href={LIBERTY_FOOTER.links.cybertrust}
              target='cybertrust'
            />
            <Link
              className='LibertyFooter-icon LibertyFooter-bbb'
              href={LIBERTY_FOOTER.links.bbb}
              target='bbb'
            />
            <Link
              className='LibertyFooter-icon LibertyFooter-verisign'
              href={LIBERTY_FOOTER.links.verisign}
              target='verisign'
            />
          </Grid>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default LibertyFooter;
