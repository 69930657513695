import React, { useContext } from 'react';
import { Box, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, GridCol, GridRow, Heading, Modal, Radio, RadioGroup }  from '@lmig/lmds-react';
import { FormContext, setSubmitHeader, setSubmitText } from '../FormContext/FormContext';
import Details from '../Pages/Details';
import UploadReason from '../Pages/UploadReason';
import UploadDocument from '../Pages/UploadDocument';
import Review from '../Pages/Review';
import { STEP_NAMES, HEADINGS, PERSONA_MODAL } from '../constants';

const steps = [
  STEP_NAMES.STEP_ONE.label,
  STEP_NAMES.STEP_TWO.label,
  STEP_NAMES.STEP_THREE.label,
  STEP_NAMES.STEP_FOUR.label
];
const pages = [<Details key={0}/>, <UploadReason key={1}/>, <UploadDocument key={2}/>, <Review key={3}/>];
const headings = [
  HEADINGS.DETAILS.CLAIM,
  HEADINGS.REASON,
  HEADINGS.UPLOAD,
  HEADINGS.REVIEW
];

const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepIcon-root': {
    color: '#9D9E9E',
    '&.Mui-active': {
      color: '#28A3AF'
    },
    '&.Mui-completed': {
      color: '#28A3AF'
    }
  }
});

const Controller = () => {
  const [values, handleSetValues] = useContext(FormContext);
  const options = PERSONA_MODAL.PERSONA_OPTIONS;
  const isFinalStep = values.activeStep === steps.length - 1;
  const submitProgress = values.submitProgress;

  return (
    <Box sx={{ width: '100%', paddingTop: '1.25rem' }}>
      <Stepper activeStep={values.activeStep} alternativeLabel>
        {steps.map((label) => {
          const stepProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StyledStepLabel>
                {label}
              </StyledStepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Heading type='h4' align='center' style={{ maxWidth: 'inherit', paddingTop: '1.25rem' }}>
        {headings[values.activeStep]}
      </Heading>
      {pages[values.activeStep]}
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box width='15rem' pb='1rem'>
          <Button
            isLargeOnMobile
            type='submit'
            variant='primary'
            style={{ width: '-webkit-fill-available' }}
            onClick={(e) => handleSetValues('activeStep', e)}
          >
            {isFinalStep ? 'Submit' : 'Next'}
          </Button>
        </Box>
        <Box sx={{ flex: '1 1 auto' }} />
        <Box width='10rem'>
          <Button
            disabled={values.activeStep === 0}
            isLargeOnMobile
            style={{ width: '-webkit-fill-available' }}
            onClick={(e) => handleSetValues('activeStep', e)}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Modal
        isOpen={values.showSubmitModal}
        mandatory
        size='small'
        title={setSubmitHeader(submitProgress)}
      >
        <GridCol>
          <GridRow base={3}>
            {setSubmitText(submitProgress)}
            {(submitProgress === 'submitting') ? <CircularProgress style={{ marginTop: '1.25rem' }} /> :
              <Button
                onClick={(e) => handleSetValues('submitComplete', e)}
                size='small'
                variant='secondary'
                autoFocus
                dynamicWidth
                style = {{ marginTop: '1.25rem' }}
              >
                OK
              </Button>
            }
          </GridRow>
        </GridCol>
      </Modal>
      <Modal
        isOpen={values.showPersonaModal}
        mandatory
        size='medium'
      >
        <Heading tag="span" type="h4-bold">
          {PERSONA_MODAL.HEADER}
        </Heading>
        <GridCol>
          <GridRow base={3} style={{ marginTop: '0.75rem' }}>
            <RadioGroup
              name='radioGroup--persona'
              labelVisual={PERSONA_MODAL.RADIO_GROUP_TEXT}
              labelA11y={PERSONA_MODAL.RADIO_GROUP_SR_TEXT}
              value={values.contactPersona}
              onChange={(e) => handleSetValues('persona', e)}
            >
              {options.map((option) => (
                <Radio
                  labelVisual={option}
                  value={option}
                  key={option}
                />
              ))}
            </RadioGroup>
            <Button
              onClick={(e) => handleSetValues('personaSelected', e)}
              size='small'
              variant='secondary'
              autoFocus
              dynamicWidth
              style = {{ marginTop: '1.25rem' }}
            >
              Continue
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
    </Box>
  );
};

export default Controller;
