// 0.00045 MB / 0.45KB DocSol Limit
const MIN_FILE_SIZE = (0.45 / 1024);
// 10MB limit for each individual document upload
const MAX_FILE_SIZE = 10;
// 50MB limit for all documents uploaded
const MAX_TOTAL_FILE_SIZE = 50;
const MAX_FILE_NAME_LENGTH = 100;
// 200 file limit across all dropzones
const MAX_FILE_COUNT = 200;

const ACCEPTED_FILES = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/rtf': ['.rtf'],
  'application/rtf': ['.rtf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};

const ACCEPTED_IMAGES = {
  'image/png':['.png'],
  'image/bmp':['.bmp'],
  'image/jpeg':['.jpg','.jpeg'],
  'image/tiff':['.tiff','.tif'],
  'application/pdf': ['.pdf']
};

const STEP_NAMES = {
  STEP_ONE: { label: 'Details', value:'details' },
  STEP_TWO: { label:'Supplement Type' , value:'supplementType' },
  STEP_THREE: { label:'Document Upload', value:'documentUpload' },
  STEP_FOUR: { label:'Review',  value:'review' }
};

const HEADINGS = {
  DETAILS: {
    CLAIM: 'Tell us about your supplement',
    CONTACT: 'What are your contact details?',
    TAX_ID_CONTRACTOR: 'What is your business Federal Tax ID?',
    TAX_ID_NON_CONTRACTOR: "What is your contractor's Federal Tax ID?",
    GOOGLE_CONTRACTOR: "Let's help you find your business address",
    GOOGLE_NON_CONTRACTOR: "Let's help you find your contractor's address",
    ADDRESS: 'Have we got these details correct?'
  },
  REASON: 'Please describe the nature of your supplement',
  UPLOAD: 'Please upload your Supplement below',
  REVIEW: 'Please review all details below'
};

const NOTIFICATIONS = {
  ESTIMATE_TOTAL_VALUE: 'Please provide below the total estimate amount (including original estimate, previous supplements,' +
    ' and this supplement).',
  SUPPLEMENT_VALUE: 'Please provide below the supplement amount for this submission (not including original estimate' +
    ' and not including previous supplements).',
  GOOGLE_CONTRACTOR: 'Start typing your business name or address below',
  GOOGLE_NON_CONTRACTOR: 'Start typing your contractor\'s business name or address below',
  UPLOAD_TITLE: 'Important',
  UPLOAD_INFO: 'Please upload your Supplement Estimate and any other supporting documentation into the fields above',
  UPLOAD_BULLET_A: 'Each document should be less than 10MB in size',
  UPLOAD_BULLET_B: 'Total upload size for all documents should not exceed 50 MB',
  REVIEW_SUBMIT: 'Press Submit to send all information to your Adjuster'
};

const DETAILS = {
  claimNumber: {
    label: 'Claim number',
    placeholder: 'XXXXXXXXX',
    type: 'text',
    mask: undefined,
    filter: /^\d{1,9}$/
  },
  uiTotalAmount: {
    label: 'Total estimate amount ($)',
    placeholder: '0.00',
    type: 'text',
    mask: undefined,
    filter: /^(\d|[1-9]\d{1,5})(\.\d{0,2})?$/
  },
  uiSupplementAmount: {
    label: 'Supplement amount ($)',
    placeholder: '0.00',
    type: 'text',
    mask: undefined,
    filter: /^(\d|[1-9]\d{1,5})(\.\d{0,2})?$/
  },
  repairsComplete: {
    label: 'Have your repairs been completed?',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ]
  },
  linesOfCoverage: {
    label: 'Select all lines of coverage that apply.',
    reviewLabel: 'Lines of coverage',
    options: [
      'Dwelling',
      'Other Structure',
      'Personal Property',
      'Additional Living Expenses'
    ]
  },
  usingContractor: {
    label: 'Who is completing the repairs?',
    options: [
      { label: 'I am using a contractor', value: 'true' },
      { label: 'I am not using a contractor', value: 'false' }
    ]
  },
  contactName: {
    label: 'Contact person',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^[a-z ,.'-]{1,200}$/i
  },
  contactEmail: {
    label: 'Email',
    placeholder: '',
    type: 'email',
    mask: undefined,
    filter: /^.{1,200}$/
  },
  contactPhoneNumber: {
    label: 'Phone',
    placeholder: '',
    type: 'tel',
    mask: 'phone',
    filter: undefined
  },
  contractorTaxId: {
    label: 'Federal Tax ID',
    placeholder: 'XX-XXXXXXX',
    type: 'text',
    mask: undefined,
    filter: /^\d{0,2}$|^(\d{2}-\d{0,7})$/
  },
  contractorName: {
    label: 'Contractor business name',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^.{1,200}$/
  },
  contractorAddress: {
    label: 'Address',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^.{1,200}$/
  },
  contractorCity: {
    label: 'City',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: /^.{1,200}$/
  },
  contractorState: {
    label: 'State',
    placeholder: '',
    type: undefined,
    mask: undefined,
    filter: undefined
  },
  contractorZipCode: {
    label: 'Zipcode',
    placeholder: '',
    type: undefined,
    mask: 'zipCode',
    filter: /^\d{0,5}$/
  },
  contractorEmail: {
    label: 'Email',
    placeholder: '',
    type: 'email',
    mask: undefined,
    filter: /^.{1,200}$/
  },
  contractorPhoneNumber: {
    label: 'Phone',
    placeholder: '',
    type: 'tel',
    mask: 'phone',
    filter: undefined
  }
};

const REASON = {
  FREETEXT_PLACEHOLDER: 'Add a description of your supplement request here...',
  CATEGORIES_TEXT: 'Please select all categories that apply.',
  TAG_OPTIONS: [
    'Scope Change',
    'Pricing Differences',
    'Additional Damage',
    'Overhead & Profit',
    'Other'
  ],
  OTHER_CATEGORY: {
    CATEGORY: 'Other',
    TEXT: 'Please specify the other category.',
    LABEL: 'Other Category',
    PLACEHOLDER: '',
    FILTER: /^.{1,200}$/
  }
};

const UPLOADS = {
  ESTIMATE: {
    CATEGORY: 'estimate',
    HEADING: 'Primary Estimate or Invoice',
    DROPZONE: 'Drag \'n\' drop or click to select the primary supplement estimate or invoice document. (One document upload permitted)',
    POPOVER: {
      TEXT_START: 'Please upload one of the following documents.',
      LIST_ITEM_A: 'Estimate',
      LIST_ITEM_B: 'Invoice',
      TEXT_END: 'Where possible, your document should be in PDF format.'
    },
    FILE_LABEL: 'Estimate'
  },
  PHOTOS: {
    CATEGORY: 'photos',
    HEADING: 'Photos',
    DROPZONE: 'Drag \'n\' drop or click to select all photos relating to your supplement',
    POPOVER: {
      TEXT_START: 'Please provide photographic evidence to support your supplement.',
      TEXT_MIDDLE: 'Photographs should be clear and should capture the damage and/or repairs described in your invoice or estimate.',
      TEXT_END: 'All common image formats are accepted.'
    },
    FILE_LABEL: 'Photo'
  },
  OTHER: {
    CATEGORY: 'other',
    HEADING: 'All other documents',
    DROPZONE: 'Drag \'n\' drop or click to select all other documents relating to your supplement',
    POPOVER: {
      TEXT_START: 'Please upload all remaining supporting documents. This can include:',
      LIST_ITEM_A: 'Code support documents',
      LIST_ITEM_B: 'Supporting invoices',
      LIST_ITEM_C: 'Contracts',
      LIST_ITEM_D: 'Photo reports',
      LIST_ITEM_E: 'Etc.'
    },
    FILE_LABEL: 'Other'
  }
};

const REVIEW = {
  COMPANY_HEADING: 'Supplement details',
  SUPPLEMENT_HEADING: 'Supplement type',
  DOCUMENTS_HEADING: 'Documents and images',
  CONTRACTOR_COMPANY_DETAILS_HEADING: 'Business details',
  NON_CONTRACTOR_COMPANY_DETAILS_HEADING: 'Contractor details'
};

const PERSONA_MODAL = {
  HEADER: 'Welcome',
  RADIO_GROUP_TEXT: 'To begin your request, please tell us which of the following best describes you',
  RADIO_GROUP_SR_TEXT: 'Choose one from the following options which best describes you',
  PERSONA_OPTIONS: [
    'I am an insured Policyholder',
    'I am a Contractor',
    'Other'
  ]
};

const SUBMIT_MODAL = {
  HEADERS: {
    SUBMITTING: 'Please wait',
    SUCCESS: 'Thank you!',
    ERROR: 'Error'
  },
  TEXT: {
    SUBMITTING: 'Your supplement is being uploaded',
    SUCCESS: 'Once the supplement has been reviewed a member of staff will contact you to discuss supplement details',
    ERROR: 'Sorry! An unexpected error has occurred, please contact the Adjuster listed on the estimate'
  }
};

const ERROR_LIST = {
  claimNumber: 'Please provide a valid 9 digit claim number.',
  uiTotalAmount: 'The Total estimate amount field cannot be left blank.',
  uiSupplementAmount: 'The Supplement amount field cannot be left blank.',
  linesOfCoverage: 'Please select one or more lines of coverage',
  contactName:'Please provide a name so we can contact you in the event of any queries.',
  contactEmail: 'Please provide a valid email address.',
  contactPhoneNumber: 'Please provide a valid telephone number.',
  contractorTaxId: 'Please provide a valid Federal Tax ID in the format of XX-XXXXXXX or leave the field empty.',
  contractorName: 'Please provide a company name.',
  contractorAddress: 'Please provide an address.',
  contractorCity: 'Please provide a city.',
  contractorState: 'Please select a valid state.',
  contractorZipCode: 'Please provide a valid zip code.',
  contractorEmail: 'Please provide a valid company email address',
  contractorPhoneNumber: 'Please provide a valid company telephone number.',
  freeText: 'Please provide a description to help us better understand the nature of your upload.',
  otherCategory: 'Please specify the other category.',
  noSupplement: 'You must upload ONE supplement estimate or invoice.',
  invalidSupplement: 'Please remove the invalid supplement estimate file.',
  invalidPhotos: 'Please remove the invalid photo file(s).',
  invalidOtherDocuments: 'Please remove the invalid other document file(s).'
};

const isMandatory = (val) => val !== null && val !== undefined && val.length > 0;

const STATE_LIST = [
  { code: 'AL', contractorState: 'Alabama' },
  { code: 'AK', contractorState: 'Alaska' },
  { code: 'AZ', contractorState: 'Arizona' },
  { code: 'AR', contractorState: 'Arkansas' },
  { code: 'CA', contractorState: 'California' },
  { code: 'CO', contractorState: 'Colorado' },
  { code: 'CT', contractorState: 'Connecticut' },
  { code: 'DE', contractorState: 'Delaware' },
  { code: 'DC', contractorState: 'District of Columbia' },
  { code: 'FL', contractorState: 'Florida' },
  { code: 'GA', contractorState: 'Georgia' },
  { code: 'HI', contractorState: 'Hawaii' },
  { code: 'ID', contractorState: 'Idaho' },
  { code: 'IL', contractorState: 'Illinois' },
  { code: 'IN', contractorState: 'Indiana' },
  { code: 'IA', contractorState: 'Iowa' },
  { code: 'KS', contractorState: 'Kansas' },
  { code: 'KY', contractorState: 'Kentucky' },
  { code: 'LA', contractorState: 'Louisiana' },
  { code: 'ME', contractorState: 'Maine' },
  { code: 'MD', contractorState: 'Maryland' },
  { code: 'MA', contractorState: 'Massachusetts' },
  { code: 'MI', contractorState: 'Michigan' },
  { code: 'MN', contractorState: 'Minnesota' },
  { code: 'MS', contractorState: 'Mississippi' },
  { code: 'MO', contractorState: 'Missouri' },
  { code: 'MT', contractorState: 'Montana' },
  { code: 'NE', contractorState: 'Nebraska' },
  { code: 'NV', contractorState: 'Nevada' },
  { code: 'NH', contractorState: 'New Hampshire' },
  { code: 'NJ', contractorState: 'New Jersey' },
  { code: 'NM', contractorState: 'New Mexico' },
  { code: 'NY', contractorState: 'New York' },
  { code: 'NC', contractorState: 'North Carolina' },
  { code: 'ND', contractorState: 'North Dakota' },
  { code: 'OH', contractorState: 'Ohio' },
  { code: 'OK', contractorState: 'Oklahoma' },
  { code: 'OR', contractorState: 'Oregon' },
  { code: 'PA', contractorState: 'Pennsylvania' },
  { code: 'RI', contractorState: 'Rhode Island' },
  { code: 'SC', contractorState: 'South Carolina' },
  { code: 'SD', contractorState: 'South Dakota' },
  { code: 'TN', contractorState: 'Tennessee' },
  { code: 'TX', contractorState: 'Texas' },
  { code: 'UT', contractorState: 'Utah' },
  { code: 'VT', contractorState: 'Vermont' },
  { code: 'VA', contractorState: 'Virginia' },
  { code: 'WA', contractorState: 'Washington' },
  { code: 'WV', contractorState: 'West Virginia' },
  { code: 'WI', contractorState: 'Wisconsin' },
  { code: 'WY', contractorState: 'Wyoming' }
];

const LIBERTY_FOOTER = {
  baseUrl: 'https://www.libertymutualgroup.com/about-lm/',
  links: {
    privacy: 'our-company/privacy-policy',
    security: 'our-company/security-policy',
    accessibility: 'corporate-information/web-accessibility-policy',
    terms: 'our-company/terms-and-conditions',
    fraud: 'our-company/fraud-protection-tips',
    equal:
      'corporate-information/equal-employment-opportunity-and-anti-discrimination-policy',
    cybertrust:
      'https://smp-01.verizonbusiness.com/certinfo/certified.do?CERTID=072505G7001',
    bbb: 'https://www.bbb.org/boston/business-reviews/insurance-services/liberty-mutual-group-in-boston-ma-89/#bbbonlineclick',
    verisign:
      'https://sealinfo.websecurity.norton.com/splash?form_file=fdf/splash.fdf&type=GOLD&sealid=1&dn=www.libertymutual.com&lang=en'
  }
};

const GOOGLE_LIBRARIES = ['places'];

const limitStringToUTF8MB3 = (string) => {
  // Surrogate characters are reserved for constructing characters outside the Basic Multilingual Plane.
  const unicodeSurrogates = /[\uD800-\uDFFF]/g;
  // The output is a string of characters within the BMP / 3 Bytes of UTF-8.
  return string.replace(unicodeSurrogates, '');
};

const TRACK_EVENTS = {
  STEP_CHANGE : 'stepChange',
  LINE_OF_COVERAGE: 'lineOfCoverage',
  SUPPLEMENT_CATEGORY: 'supplementCatagory',
  PERSONA_SELECTED: 'personaSelected',
  SUBMIT: 'submitComplete',
  SUBMIT_ERROR: 'submitError'
};

export {
  ACCEPTED_FILES,
  ACCEPTED_IMAGES,
  STEP_NAMES,
  HEADINGS,
  NOTIFICATIONS,
  DETAILS,
  REASON,
  UPLOADS,
  REVIEW,
  ERROR_LIST,
  STATE_LIST,
  LIBERTY_FOOTER,
  GOOGLE_LIBRARIES,
  PERSONA_MODAL,
  SUBMIT_MODAL,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_FILE_NAME_LENGTH,
  MAX_FILE_COUNT,
  MIN_FILE_SIZE,
  isMandatory,
  limitStringToUTF8MB3,
  TRACK_EVENTS
};
