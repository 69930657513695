import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Button, FieldGroup, GridCol, GridRow, List, ListItem, Modal, Notification } from '@lmig/lmds-react';
import { MAX_TOTAL_FILE_SIZE, MAX_FILE_COUNT, NOTIFICATIONS, UPLOADS } from '../constants';
import DocumentUpload from '../Components/DocumentUpload';
import ThumbnailsGrid from '../Components/ThumbnailsGrid';
import { FormContext } from '../FormContext/FormContext';
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile';

const UploadDocument = () => {
  const [values, handleSetValues, setErrorMsg] = useContext(FormContext);
  const dropzoneOrientation = useCheckIsMobile() ? 'column' : 'row';
  const uploadsErrors = setErrorMsg('uploads', ['noSupplement', 'invalidSupplement']);
  const photosErrors = setErrorMsg('uploads', ['invalidPhotos']);
  const otherDocumentsErrors = setErrorMsg('uploads', ['invalidOtherDocuments']);

  return (
    <Box style={{ padding: '20px' }}>
      <Box
        alignItems='center'
        display='flex'
        flexDirection={dropzoneOrientation}
        justifyContent='center'
      >
        <FieldGroup
          isFull
          disableMargins='all'
          messages={uploadsErrors}
          messagesRole='status'
        >
          <DocumentUpload
            category={UPLOADS.ESTIMATE.CATEGORY}
            heading={UPLOADS.ESTIMATE.HEADING}
            popoverText={UPLOADS.ESTIMATE.POPOVER}
            hasError={Array.isArray(uploadsErrors) && uploadsErrors.length > 0}
          />
        </FieldGroup>
        <FieldGroup
          isFull
          disableMargins='all'
          messages={photosErrors}
          messagesRole='status'
        >
          <DocumentUpload
            category={UPLOADS.PHOTOS.CATEGORY}
            heading={UPLOADS.PHOTOS.HEADING}
            popoverText={UPLOADS.PHOTOS.POPOVER}
            hasError={Array.isArray(photosErrors) && photosErrors.length > 0}
          />
        </FieldGroup>
        <FieldGroup
          isFull
          disableMargins='all'
          messages={otherDocumentsErrors}
          messagesRole='status'
        >
          <DocumentUpload
            category={UPLOADS.OTHER.CATEGORY}
            heading={UPLOADS.OTHER.HEADING}
            popoverText={UPLOADS.OTHER.POPOVER}
            hasError={Array.isArray(otherDocumentsErrors) && otherDocumentsErrors.length > 0}
          />
        </FieldGroup>
      </Box>
      <ThumbnailsGrid category={UPLOADS.ESTIMATE.CATEGORY} />
      <Notification
        alert={NOTIFICATIONS.UPLOAD_TITLE}
        highlightType='informative'
        style={{ marginBottom: '20px' }}
      >
        <p>{NOTIFICATIONS.UPLOAD_INFO}</p>
        <li>{NOTIFICATIONS.UPLOAD_BULLET_A}</li>
        <li>{NOTIFICATIONS.UPLOAD_BULLET_B}</li>
      </Notification>
      <Modal
        isOpen={values.uploads.minFileSizeError}
        mandatory
        size='large'
        modalStyle='error'
        title='Failed to upload file(s):'
      >
        <List>
          {Array.from(values.uploads.minFileSizeNames).map((value, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </List>
          The above files have failed to be read or are below 450 bytes in size. Please try again.
        <br />
        <br />
        <GridCol>
          <GridRow base={3} justify='end'>
            <Button
              size='small'
              onClick={(e) =>
                handleSetValues('clearMinFileSizeError', e)}
              variant='secondary'
              autoFocus
            >
              OK
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
      <Modal
        isOpen={values.uploads.maxFileSizeError}
        mandatory
        size='large'
        modalStyle='error'
        title='Following files exceed 10MB limit:'
      >
        <List>
          {Array.from(values.uploads.maxFileSizeNames).map((value, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </List>
          The above files have not been uploaded.
        <br />
        <br />
        <GridCol>
          <GridRow base={3} justify='end'>
            <Button
              size='small'
              onClick={(e) =>
                handleSetValues('clearMaxFileSizeError', e)}
              variant='secondary'
              autoFocus
            >
              OK
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
      <Modal
        isOpen={values.uploads.maxTotalFileSizeError}
        mandatory
        size='large'
        modalStyle='error'
        title='Failed to upload file(s):'
      >
        <List>
          {Array.from(values.uploads.maxTotalFileSizeNames).map((value, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </List>
          The above file has not been uploaded because the combined total size of all uploads would exceed {MAX_TOTAL_FILE_SIZE}MB.
        <br />
        <br />
        <GridCol>
          <GridRow base={3} justify='end'>
            <Button
              size='small'
              onClick={(e) =>
                handleSetValues('clearMaxTotalFileSizeError', e)}
              variant='secondary'
              autoFocus
            >
              OK
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
      <Modal
        isOpen={values.uploads.maxFileCountError}
        mandatory
        size='large'
        modalStyle='error'
        title='Failed to upload file(s):'
      >
        <List>
          {Array.from(values.uploads.maxFileCountNames).map((value, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </List>
          The file upload limit of {MAX_FILE_COUNT} has been reached. The above files have not been uploaded.
        <br />
        <br />
        <GridCol>
          <GridRow base={3} justify='end'>
            <Button
              size='small'
              onClick={(e) =>
                handleSetValues('clearMaxFileCountError', e)}
              variant='secondary'
              autoFocus
            >
              OK
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
    </Box>
  );
};

export default UploadDocument;
