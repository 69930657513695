import React, { useContext } from 'react';
import { FormContext } from '../FormContext/FormContext';
import { getRequireContractorDetails, isContractorField } from '../FormContext/DetailsContext';
import { Box, Grid, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import {
  IconSuccessAlert,
  List,
  ListItem,
  IconAutoHome,
  IconDocument,
  IconDocumentMulti,
  Notification,
  BodyText
} from '@lmig/lmds-react';
import { DETAILS, NOTIFICATIONS, REASON, REVIEW } from '../constants';
import { imageContent } from '../FormContext/UploadsContext';
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile';

const Review = () => {
  const [values] = useContext(FormContext);
  const { details, isContractor, uploads } = values;
  const requireContractorDetails = getRequireContractorDetails(values);
  const generalDetailsItems = [];
  const contractorDetailsItems = [];

  const cardStyle = {
    padding: '0 0 16px 0',
    marginBottom: '16px',
    border: '1px solid #919191',
    borderRadius: '4px',
    fontSize: '1.1em'
  };

  const iconStyle = {
    padding: '4px'
  };

  const cardTitleStyle = {
    padding: '8px 16px',
    marginBottom: '16px',
    borderBottom: '1px solid #919191'
  };

  const cardCopyStyle = {
    paddingLeft: '6px'
  };

  const cardContentStyle = {
    padding: '6px 16px'
  };

  const contractorDetailsSectionStyle = {
    paddingTop: '16px'
  };

  const contractorDetailsHeadingStyle = {
    padding: '0px 16px 6px'
  };
  
  const getDetailsLabel = (name) => {
    const field = DETAILS[name];
    return field.reviewLabel || field.label;
  };
  
  const getDetailsValue = (name) => {
    const field = details[name];
    switch (name) {
    case 'linesOfCoverage':
      return field.value.join(', ');
    case 'usingContractor':
      return DETAILS.usingContractor.options.find(({ value }) => value === field.value).label;
    default:
      return field.value;
    }
  };

  const getContractorDetailsHeading = () => {
    const { isContractor } = values;
    const { usingContractor } = details;
    let contractorDetailsHeading = '';
    if (isContractor) {
      contractorDetailsHeading = REVIEW.CONTRACTOR_COMPANY_DETAILS_HEADING;
    } else if (usingContractor.value === 'true') {
      contractorDetailsHeading = REVIEW.NON_CONTRACTOR_COMPANY_DETAILS_HEADING;
    }
    return contractorDetailsHeading;
  };

  (function generateDetailsItems() {
    Object.keys(details).forEach((keyName) => {
      if (isContractor) {
        switch (keyName) {
        case 'contractorEmail':
        case 'usingContractor':
          return;
        }
      }
      const item = (
        <Grid key={keyName} style={cardContentStyle} container direction="row">
          <Grid item xs={5} zeroMinWidth>
            {`${getDetailsLabel(keyName)}:`}
          </Grid>
          <Grid item xs={7} zeroMinWidth style={{ overflow: 'auto' }}>
            <strong>{`${getDetailsValue(keyName)}`}</strong>
          </Grid>
        </Grid>
      );
      if (!isContractorField(keyName)) {
        generalDetailsItems.push(item);
      } else {
        contractorDetailsItems.push(item);
      }
    });
  }());

  return (
    <Box style={{ padding: '20px' }}>
      <Box style={cardStyle}>
        <Box style={cardTitleStyle}>
          <IconAutoHome title="Icon Auto Home" style={iconStyle} />
          <span style={cardCopyStyle}>{REVIEW.COMPANY_HEADING}</span>
        </Box>
        {generalDetailsItems}
        {requireContractorDetails && (
          <Grid style={contractorDetailsSectionStyle}>
            <strong style={contractorDetailsHeadingStyle}>{getContractorDetailsHeading()}</strong>
            {contractorDetailsItems}
          </Grid>
        )}
      </Box>

      <Box style={cardStyle}>
        <Box style={cardTitleStyle}>
          <IconDocument title="Icon Document" style={iconStyle} />
          <span style={cardCopyStyle}>{REVIEW.SUPPLEMENT_HEADING}</span>
        </Box>
        <Grid style={cardContentStyle}>
          <BodyText>Description: {values.reason.freeText}</BodyText>
          <br />
                    Upload Tags:
          <List icon={<IconSuccessAlert color='positive' />}>
            {values.reason.tags.map((tag, i) => {
              let itemText = tag;
              if (tag === REASON.OTHER_CATEGORY.CATEGORY) itemText = `${tag} - ${values.reason.otherCategory}`;

              return (<ListItem key={`${tag.id} - ${i}`}>{itemText}</ListItem>);  
            })}
          </List>
        </Grid>
      </Box>

      <Box style={cardStyle}>
        <Box style={cardTitleStyle}>
          <IconDocumentMulti title="Icon Documents" style={iconStyle} />
          <span style={cardCopyStyle}>{REVIEW.DOCUMENTS_HEADING}</span>
        </Box>
        <Grid item xs={12} zeroMinWidth style={{ padding: '0 16px 0' }}>
          <ImageList rowHeight={160} cols={useCheckIsMobile() ? 2 : 5}>
            {uploads.files.map((file, i) => (
              <ImageListItem key={`${file.id} - ${i}`}>
                {imageContent(file)}
                <ImageListItemBar title={file.name} />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Box>
      <Notification alert={NOTIFICATIONS.REVIEW_SUBMIT} highlightType='informative'/>
    </Box>
  );
};

export default Review;
