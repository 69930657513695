import React from 'react';
import { Notification } from '@lmig/lmds-react';

const SplashPage = () => (
  <Notification
    alert="Site down for maintenance"
    highlightType="informative"
  >
    Our site is currently down for maintenance as we strive to improve
    your user experience.
    <br />
    <br />
    Please check back shortly and{' '}
    <em>
      <strong>refresh this page</strong>
    </em>{' '}
    as we should be up and running again soon.
  </Notification>
);

export default SplashPage;
