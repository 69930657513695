import React, { useContext } from 'react';
import { FormContext } from '../FormContext/FormContext';
import { Radio, RadioGroup } from '@lmig/lmds-react';
import { DETAILS } from '../constants';

const RadioButtons = (props) => {
  const [values, handleSetValues] = useContext(FormContext);
  const details = values.details;
  // eslint-disable-next-line react/prop-types
  const { valueKey } = props;

  return (
    <RadioGroup
      labelVisual={DETAILS[valueKey].label}
      name={valueKey}
      value={details[valueKey].value}
      onChange={(e) => handleSetValues('details', e)}
    >
      { DETAILS[valueKey].options.map((option, index) => <Radio key={index} labelVisual={option.label} value={option.value} />) }
    </RadioGroup>
  );
};

export default RadioButtons;
